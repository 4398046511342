<template>
  <div class="privacy-policy relative landing-page-wrapper new-langing-ui">
    <landing-page-header
    />
    <div
      v-if="pageContent"
      class="container content-info"
      v-html="pageContent.content"
    />
    <landing-page-footer />
    <AppFooterBlack />
  </div>
</template>

<script>
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import axios from 'axios'
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import { hideLoader, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'

export default {
  components: {
    LandingPageFooter,
    LandingPageHeader,
    AppFooterBlack,
  },
  data() {
    return {
      pageContent: null,
    }
  },
  mounted() {
    showLoader()
    this.getPageInfo()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          // eslint-disable-next-line prefer-destructuring
          this.pageContent = data.responseData[0]
          window.SuperApp.actions.setLandingPageHeight()
        }
        hideLoader()
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
  },
  methods: {
    getPageInfo() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${jwtDefaultConfig.baseEndpoint}/cmspage/page_privacy`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/landing-page.scss';
</style>
